// extracted by mini-css-extract-plugin
export var arGradientBlue = "ArHomepagePlatformSelect-module--ar-gradient-blue--FZxjA";
export var arGradientLight = "ArHomepagePlatformSelect-module--ar-gradient-light--91cyR";
export var arH1 = "ArHomepagePlatformSelect-module--ar-h1--z80VP";
export var arH1Mobile = "ArHomepagePlatformSelect-module--ar-h1-mobile--PAFxU";
export var arH2 = "ArHomepagePlatformSelect-module--ar-h2--62oBM";
export var arH2Mobile = "ArHomepagePlatformSelect-module--ar-h2-mobile--lyVA7";
export var arH3 = "ArHomepagePlatformSelect-module--ar-h3--NAFzn";
export var arH3Mobile = "ArHomepagePlatformSelect-module--ar-h3-mobile--VyADn";
export var arH4 = "ArHomepagePlatformSelect-module--ar-h4--3LWE+";
export var arH4Mobile = "ArHomepagePlatformSelect-module--ar-h4-mobile--HKDIV";
export var arH5 = "ArHomepagePlatformSelect-module--ar-h5--uYO1g";
export var arH5Mobile = "ArHomepagePlatformSelect-module--ar-h5-mobile--bB+yY";
export var arLinkDesktop = "ArHomepagePlatformSelect-module--ar-link-desktop--IXGgk";
export var arLinkMobile = "ArHomepagePlatformSelect-module--ar-link-mobile--fHPyY";
export var arParagraphBase = "ArHomepagePlatformSelect-module--ar-paragraph-base--mXCgH";
export var arParagraphBaseMedium = "ArHomepagePlatformSelect-module--ar-paragraph-base-medium--bc5Bb";
export var arParagraphSmall = "ArHomepagePlatformSelect-module--ar-paragraph-small--4LoyK";
export var arParagraphSmallMedium = "ArHomepagePlatformSelect-module--ar-paragraph-small-medium--aVfLb";
export var arParagraphXl = "ArHomepagePlatformSelect-module--ar-paragraph-xl--aLmue";
export var arParagraphXlMedium = "ArHomepagePlatformSelect-module--ar-paragraph-xl-medium--P0zTP";
export var arParagraphXsmall = "ArHomepagePlatformSelect-module--ar-paragraph-xsmall--rHc3s";
export var arParagraphXsmallMedium = "ArHomepagePlatformSelect-module--ar-paragraph-xsmall-medium--aRPag";
export var arQuote = "ArHomepagePlatformSelect-module--ar-quote--s7m7f";
export var arQuoteMobile = "ArHomepagePlatformSelect-module--ar-quote-mobile---W8z3";
export var arTransition = "ArHomepagePlatformSelect-module--ar-transition--ZIdDv";
export var container = "ArHomepagePlatformSelect-module--container--nOTEG";
export var contentWrapper = "ArHomepagePlatformSelect-module--contentWrapper--fpGMK";
export var platforms = "ArHomepagePlatformSelect-module--platforms--bTn1J";