// extracted by mini-css-extract-plugin
export var arGradientBlue = "ArHomepageCustomerLove-module--ar-gradient-blue--iJA7o";
export var arGradientLight = "ArHomepageCustomerLove-module--ar-gradient-light--JHgE-";
export var arH1 = "ArHomepageCustomerLove-module--ar-h1--Magi8";
export var arH1Mobile = "ArHomepageCustomerLove-module--ar-h1-mobile--WhoZG";
export var arH2 = "ArHomepageCustomerLove-module--ar-h2--GmRY7";
export var arH2Mobile = "ArHomepageCustomerLove-module--ar-h2-mobile--ITbFE";
export var arH3 = "ArHomepageCustomerLove-module--ar-h3--tjfpz";
export var arH3Mobile = "ArHomepageCustomerLove-module--ar-h3-mobile--cn9Sa";
export var arH4 = "ArHomepageCustomerLove-module--ar-h4--cpXss";
export var arH4Mobile = "ArHomepageCustomerLove-module--ar-h4-mobile--BU83v";
export var arH5 = "ArHomepageCustomerLove-module--ar-h5--FiX2g";
export var arH5Mobile = "ArHomepageCustomerLove-module--ar-h5-mobile--zWhmC";
export var arLinkDesktop = "ArHomepageCustomerLove-module--ar-link-desktop--iIPzK";
export var arLinkMobile = "ArHomepageCustomerLove-module--ar-link-mobile--gte58";
export var arParagraphBase = "ArHomepageCustomerLove-module--ar-paragraph-base--2Ps8j";
export var arParagraphBaseMedium = "ArHomepageCustomerLove-module--ar-paragraph-base-medium--yMHgM";
export var arParagraphSmall = "ArHomepageCustomerLove-module--ar-paragraph-small--FqTpE";
export var arParagraphSmallMedium = "ArHomepageCustomerLove-module--ar-paragraph-small-medium--9eRI2";
export var arParagraphXl = "ArHomepageCustomerLove-module--ar-paragraph-xl--KI2RU";
export var arParagraphXlMedium = "ArHomepageCustomerLove-module--ar-paragraph-xl-medium--6qpgL";
export var arParagraphXsmall = "ArHomepageCustomerLove-module--ar-paragraph-xsmall--0x4zo";
export var arParagraphXsmallMedium = "ArHomepageCustomerLove-module--ar-paragraph-xsmall-medium--7T0uq";
export var arQuote = "ArHomepageCustomerLove-module--ar-quote--YjmpX";
export var arQuoteMobile = "ArHomepageCustomerLove-module--ar-quote-mobile--4mx+v";
export var arTransition = "ArHomepageCustomerLove-module--ar-transition--zHesm";
export var container = "ArHomepageCustomerLove-module--container--uQJR5";
export var footer = "ArHomepageCustomerLove-module--footer--Z5xne";
export var logo = "ArHomepageCustomerLove-module--logo--Acyvg";
export var quotation = "ArHomepageCustomerLove-module--quotation--TQMg3";